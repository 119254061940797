/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, {
    useState, useEffect, useCallback, useContext
} from 'react';
import { validateMetadata, renderContentFromMetadata } from '@jutro/uiconfig';
// importing TranslatorContext and LocaleService
import { TranslatorContext, LocaleService } from '@jutro/locale';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from '@jutro/components';
import message from './header.messages';
import uiMetadata from './header.metadata.json5';
import styles from './header.module.scss';
// importing UtilService
import UtilService from '../../services/UtilService';
import AnalyticsLinkService from '../../services/analyticsLink.service';
import SendMessage from '../../claim-details/messaging/send-message/send-message.messages';
import ReplyMessage from '../../claim-details/messaging/reply-message/reply-message.messages';
// import metadata for LeftNavigation.
import metadata from '../left-nav/left-nav.metadata.json5';
import OSBCClaimService from '../../services/OSBCClaimService';
import showIneligibleClaimModal from '../Ineligible-claim/Ineligible-claim';
import { useAuthentication } from 'gw-digital-auth-react';
import OpenConfirmationDialog from '../confirmation-dialog/confirmation-dialog';

export default function Header() {
    const auth = useAuthentication();
    const translator = useContext(TranslatorContext);
    const [userName, setUserName] = useState('');
    const [iAmType, setIAmType] = useState('Ill or Injured');
    const [showModal, setVisiblePropertyForModal] = useState(false);
    const [activeLocale, setActiveLocale] = useState(
        LocaleService.getStoredLocale()
    );
    const [activeLanguage, setActiveLanguage] = useState(
        LocaleService.getStoredLanguage()
    );
    const [isUserLoggedIn, setUserLoogedIn] = useState(false);
    const [showLeftNav, setshowLeftNav] = useState(false);
    const location = useLocation();

    const updateActiveLocale = useCallback((locale) => {
        UtilService.languageChanged = true;
        LocaleService.setCurrentLocale(locale);
        setActiveLocale(locale);
    }, []);

    const updateActiveLanguage = useCallback((language) => {
        UtilService.languageChanged = true;
        LocaleService.setCurrentLanguage(language);
        setActiveLanguage(language);
    }, []);

    const [count, setCount] = useState('');
    const history = useHistory();

    /**
    * getting message count method
    */
    const messageCount = useCallback(() => {
        const claimNumber = UtilService.fetchClaimNumber();
        if (claimNumber.length === 8) {
            const mailList = OSBCClaimService.messageCount(claimNumber, '');
            mailList.then((result) => {
                if (result === 0) {
                    setCount('');
                } else {
                    setCount(`(${result})`);
                }
            }, (error) => {
                // eslint-disable-next-line no-console
                if (error.baseError === '840' || error.baseError === '844' || error.baseError === '842') {
                    showIneligibleClaimModal(error.baseError);
                }
                setCount('');
            });
        }
    }, []);

    const LogoClicked = useCallback((language) => {
        const href = LocaleService.getStoredLanguage() === 'fr' ? 'https://www.wsib.ca/fr' : 'https://www.wsib.ca/';
        window.open(href, '_blank');
    }, []);

    const onLogoClick = useCallback((e) => {
        e.preventDefault();
        const isRefNoGenerated = sessionStorage.getItem('isRefNumGenerated');
        if (isRefNoGenerated) {
            if (isRefNoGenerated === 'true') {
                OpenConfirmationDialog(translator(message.YouSure),
                    translator(message.ChangeReportType),
                    translator(message.Cancel),
                    translator(message.Confirm),
                () => {},
                () => {
                    LogoClicked();
                });
            } else {
                OpenConfirmationDialog(translator(message.YouSureNoRef),
                    translator(message.ChangeReportingNoRef),
                    translator(message.ConfirmNoRef),
                    translator(message.CancelNoRef),
                () => {
                    LogoClicked();
                },
                () => {});
            }
        } else if (document.getElementById('mainBody').getAttribute('sendMessageDirty') || sessionStorage.getItem('documentAdded') === '1') {
            const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
            allowNavigate.then((status) => {
                if (status) {
                    LogoClicked();
                }
            }, () => {
            });
        } else {
            LogoClicked();
        }
    }, [translator]);

    /**
    * redirect to claim overview screen
    */
    const redirectToRoute = useCallback(
        (value) => {
            messageCount();
            if (window.location.pathname.indexOf('sendmessage') !== -1) {
                if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                    const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else if (window.location.pathname.indexOf('documentUpload') !== -1) {
                if (sessionStorage.getItem('documentAdded') === '1') {
                    const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
                if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                    const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                        document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else if (window.location.pathname.indexOf('directdeposit') !== -1) {
                if (sessionStorage.getItem('directDepositDirty') === '1') {
                    const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else {
                history.push(`/${value}`);
            }
        },
        [history, messageCount, translator]
    );

    /*
     * to fetch loggedin user name, Integration service will be called?
     */
    const fetchUserName = () => {
        if (auth.isLoggedIn) {
            setUserName(auth.name);
            setUserLoogedIn(true);
        } else {
            setUserLoogedIn(false);
        }
    };

    /*
     * to check route path, visivility of sidenav will be handled
     */
    const displaySidenav = () => {
        if ((['/account-management/claims', '/account-management/addclaim',
            '/account-management/session-expired'].indexOf(window.location.pathname) !== -1)
        || (window.location.pathname.indexOf('/account-management/enrollmentconfirmation') !== -1)
        || (window.location.pathname.indexOf('/account-management/directdeposit') !== -1)) {
            setshowLeftNav(true);
        } else {
            setshowLeftNav(false);
        }
    };

    const logoutClicked = () => {
        sessionStorage.removeItem('isRefNumGenerated');
        sessionStorage.removeItem('docuploadtokenexp');
        sessionStorage.removeItem('docuploadtoken');
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty') || sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                auth.logout().then(() => {
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                });
            } else {
                auth.logout().then(() => {
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                auth.logout().then(() => {
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else if (window.location.pathname.indexOf('addclaim') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                auth.logout().then(() => {
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else if (window.location.pathname.indexOf('directdeposit') !== -1) {
            if (sessionStorage.getItem('directDepositDirty') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                });
            } else {
                auth.logout().then(() => {
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else {
            auth.logout().then(() => {
                sessionStorage.clear();
                UtilService.deleteAllCookies();
                window.location.href = UtilService.unifiedLogout(activeLanguage);
            });
        }
    };

    /*
     * to call global logout and redirect the user to LFB login screen.
     */
    const logout = useCallback(() => {
        const isRefNoGenerated = sessionStorage.getItem('isRefNumGenerated');
        if (isRefNoGenerated) {
            if (isRefNoGenerated === 'true') {
                OpenConfirmationDialog(translator(message.YouSure),
                    translator(message.ChangeReportType),
                    translator(message.Cancel),
                    translator(message.Confirm),
                () => {},
                () => {
                    logoutClicked();
                });
            } else {
                OpenConfirmationDialog(translator(message.YouSureNoRef),
                    translator(message.ChangeReportingNoRef),
                    translator(message.ConfirmNoRef),
                    translator(message.CancelNoRef),
                () => {
                    logoutClicked();
                },
                () => {});
            }
        } else if (document.getElementById('mainBody').getAttribute('sendMessageDirty') || sessionStorage.getItem('documentAdded') === '1') {
            logoutClicked();
        } else logoutClicked();
    }, [auth, activeLanguage]);

    /*
     *  get profile link
     */
    const getProfileLink = useCallback(
        () => {
            return UtilService.profileLink(activeLanguage);
        },
        [activeLanguage]
    );

    const dropDownChanged = (event) => {
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    window.location.href = UtilService.businessLink(activeLanguage);
                                    break;
                                case 'Ill or Injured':
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            window.location.href = UtilService.businessLink(activeLanguage);
                            break;
                        case 'Ill or Injured':
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    window.location.href = UtilService.businessLink(activeLanguage);
                                    break;
                                case 'Ill or Injured':
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            window.location.href = UtilService.businessLink(activeLanguage);
                            break;
                        case 'Ill or Injured':
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    window.location.href = UtilService.businessLink(activeLanguage);
                                    break;
                                case 'Ill or Injured':
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            window.location.href = UtilService.businessLink(activeLanguage);
                            break;
                        case 'Ill or Injured':
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else if (window.location.pathname.indexOf('directdeposit') !== -1) {
            if (sessionStorage.getItem('directDepositDirty') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    window.location.href = UtilService.businessLink(activeLanguage);
                                    break;
                                case 'Ill or Injured':
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            window.location.href = UtilService.businessLink(activeLanguage);
                            break;
                        case 'Ill or Injured':
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else if (window.location.pathname.indexOf('addclaim') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    window.location.href = UtilService.businessLink(activeLanguage);
                                    break;
                                case 'Ill or Injured':
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            window.location.href = UtilService.businessLink(activeLanguage);
                            break;
                        case 'Ill or Injured':
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else {
            setIAmType(() => {
                switch (event) {
                    case 'Business':
                        window.location.href = UtilService.businessLink(activeLanguage);
                        break;
                    case 'Ill or Injured':
                        break;
                    default:
                        break;
                }
                return event;
            });
        }
    };


    /*
     * navigate to Ill or Injured and Business.
     */
    const onChangeOfIamDropdown = useCallback((event) => {
        const isRefNoGenerated = sessionStorage.getItem('isRefNumGenerated');
        if (isRefNoGenerated) {
            if (isRefNoGenerated === 'true') {
                OpenConfirmationDialog(translator(message.YouSure),
                    translator(message.ChangeReportType),
                    translator(message.Cancel),
                    translator(message.Confirm),
                () => {},
                () => {
                    dropDownChanged(event);
                });
            } else {
                OpenConfirmationDialog(translator(message.YouSureNoRef),
                    translator(message.ChangeReportingNoRef),
                    translator(message.ConfirmNoRef),
                    translator(message.CancelNoRef),
                () => {
                    dropDownChanged(event);
                },
                () => {});
            }
        } else if (document.getElementById('mainBody').getAttribute('sendMessageDirty') || sessionStorage.getItem('documentAdded') === '1') {
            logoutClicked();
        } else dropDownChanged(event);
    }, [activeLanguage]);

    const navigateToProfileClicked = (event) => {
        sessionStorage.removeItem('isRefNumGenerated');
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                        window.location.href = getProfileLink();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                window.location.href = getProfileLink();
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                        window.location.href = getProfileLink();
                    }
                }, () => {
                });
            } else {
                AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                window.location.href = getProfileLink();
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                        window.location.href = getProfileLink();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                window.location.href = getProfileLink();
            }
        } else if (window.location.pathname.indexOf('directdeposit') !== -1) {
            if (sessionStorage.getItem('directDepositDirty') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                        window.location.href = getProfileLink();
                    }
                }, () => {
                });
            } else {
                AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                window.location.href = getProfileLink();
            }
        } else if (window.location.pathname.indexOf('addclaim') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                        window.location.href = getProfileLink();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
                window.location.href = getProfileLink();
            }
        } else {
            AnalyticsLinkService.sendAlternativeAnalyticsReport('MyProfile', LocaleService.currentLanguage);
            window.location.href = getProfileLink();
        }
    };

    /*
     * navigate to Ill or Injured and Business.
     */
    const navigateToMyProfile = useCallback((event) => {
        const isRefNoGenerated = sessionStorage.getItem('isRefNumGenerated');
        if (isRefNoGenerated) {
            if (isRefNoGenerated === 'true') {
                OpenConfirmationDialog(translator(message.YouSure),
                    translator(message.ChangeReportType),
                    translator(message.Cancel),
                    translator(message.Confirm),
                () => {},
                () => {
                    navigateToProfileClicked(event);
                });
            } else {
                OpenConfirmationDialog(translator(message.YouSureNoRef),
                    translator(message.ChangeReportingNoRef),
                    translator(message.ConfirmNoRef),
                    translator(message.CancelNoRef),
                () => {
                    navigateToProfileClicked(event);
                },
                () => {});
            }
        } else if (document.getElementById('mainBody').getAttribute('sendMessageDirty') || sessionStorage.getItem('documentAdded') === '1') {
            navigateToProfileClicked(event);
        } else navigateToProfileClicked(event);
    }, [activeLanguage]);


    const navigateToMyClaimsClicked = (event) => {
        sessionStorage.removeItem('isRefNumGenerated');
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
                        history.push(UtilService.getMyClaimsLink());
                        closeModal();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
                history.push(UtilService.getMyClaimsLink());
                closeModal();
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
                        history.push(UtilService.getMyClaimsLink());
                        closeModal();
                    }
                }, () => {
                });
            } else {
                history.push(UtilService.getMyClaimsLink());
                closeModal();
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
                        history.push(UtilService.getMyClaimsLink());
                        closeModal();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
                history.push(UtilService.getMyClaimsLink());
                closeModal();
            }
        } else if (window.location.pathname.indexOf('directdeposit') !== -1) {
            if (sessionStorage.getItem('directDepositDirty') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
                        history.push(UtilService.getMyClaimsLink());
                        closeModal();
                    }
                }, () => {
                });
            } else {
                history.push(UtilService.getMyClaimsLink());
                closeModal();
            }
        } else if (window.location.pathname.indexOf('addclaim') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
                        history.push(UtilService.getMyClaimsLink());
                        closeModal();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
                history.push(UtilService.getMyClaimsLink());
                closeModal();
            }
        } else {
            AnalyticsLinkService.sendLinkAnalyticsReport('yourclaim', LocaleService.currentLanguage);
            history.push(UtilService.getMyClaimsLink());
            closeModal();
        }
    };

    /*
     * navigate to Ill or Injured and Business.
     */
    const navigateToMyClaims = useCallback((event) => {
        const isRefNoGenerated = sessionStorage.getItem('isRefNumGenerated');

        if (window.location.pathname === UtilService.getMyClaimsLink()) {
            closeModal();
            return;
        }
        if (isRefNoGenerated) {
            if (isRefNoGenerated === 'true') {
                OpenConfirmationDialog(translator(message.YouSure),
                    translator(message.ChangeReportType),
                    translator(message.Cancel),
                    translator(message.Confirm),
                () => {},
                () => {
                    navigateToMyClaimsClicked(event);
                });
            } else {
                OpenConfirmationDialog(translator(message.YouSureNoRef),
                    translator(message.ChangeReportingNoRef),
                    translator(message.ConfirmNoRef),
                    translator(message.CancelNoRef),
                () => {
                    navigateToMyClaimsClicked(event);
                },
                () => {});
            }
           
        } else if (document.getElementById('mainBody').getAttribute('sendMessageDirty') || sessionStorage.getItem('documentAdded') === '1') {
            navigateToMyClaimsClicked(event);
        } else navigateToMyClaimsClicked(event);
    }, [activeLanguage, closeModal, navigateToMyClaimsClicked]);


    /*
     * to display responsive menu
     */
    const menuIconClicked = useCallback(() => {
        document.body.classList.add('noScroll');
        setVisiblePropertyForModal(() => {
            return true;
        });
    }, []);

    /*
     *  to close modal
     */
    const closeModal = useCallback(() => {
        document.body.classList.remove('noScroll');
        setVisiblePropertyForModal(() => {
            return false;
        });
    }, []);

    const languageTag = () => {
        if (activeLanguage === 'en') {
            return 'fr';
        } return 'en';
    };

    /*
     * @param {string} key
     * @returns content
     * get content on the basis of key
     */
    const fetchContent = useCallback(
        (key) => {
            return translator(message[key]);
        },
        [translator]
    );
    // eslint-disable-next-line no-trailing-spaces


    const languageChanged = () => {
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG79), translator(SendMessage.Cancel), translator(SendMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        window.location.reload();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                window.location.reload();
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG79), translator(SendMessage.Cancel), translator(SendMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        window.location.reload();
                    }
                }, () => {
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                window.location.reload();
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG79), translator(ReplyMessage.Cancel), translator(ReplyMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        window.location.reload();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                window.location.reload();
            }
        } else if (window.location.pathname.indexOf('directdeposit') !== -1) {
            if (sessionStorage.getItem('directDepositDirty') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        window.location.reload();
                    }
                }, () => {
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                window.location.reload();
            }
        } else if (window.location.pathname.indexOf('addclaim') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG79), translator(ReplyMessage.Cancel), translator(ReplyMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        window.location.reload();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                window.location.reload();
            }
        } else {
            if (activeLanguage === 'fr') {
                updateActiveLanguage('en');
                updateActiveLocale('en');
            } else {
                updateActiveLanguage('fr');
                updateActiveLocale('fr');
            }
            window.location.reload();
        }
    };

    /*
     *  change language method
     */
    const changeLanguage = useCallback(() => {
        const isRefNoGenerated = sessionStorage.getItem('isRefNumGenerated');
        if (isRefNoGenerated) {
            OpenConfirmationDialog(translator(message.YouSureChangeLanguage),
                translator(message.ChangeLanguage),
                translator(message.ConfirmNoRef),
                translator(message.CancelNoRef),
                () => {
                    languageChanged();
                },
                () => {});
        } else if (document.getElementById('mainBody').getAttribute('sendMessageDirty') || sessionStorage.getItem('documentAdded') === '1') {
            languageChanged();
        } else languageChanged();
    }, [activeLanguage, UtilService.navigateAway, updateActiveLanguage, updateActiveLocale]);

    /*
     * @param {string} key
     * @returns Link Component
     * To get the Link and message based on key and active language
     */
    const getExitLink = useCallback(
        () => {
            const href = UtilService.onlineServices(LocaleService.getCurrentLanguage());
            return (
                <Link href={href} className={styles.headerHyperLinks}>
                    {translator(message.exit)}
                </Link>
            );
        },
        [translator]
    );

    /*
     * @param {string} key
     * @returns Link Component
     * To get the Link and message based on key and active language
     */
    const navigateLink = useCallback(
        (key) => {
            const claimNumber = UtilService.fetchClaimNumber();
            switch (key) {
                case 'claimOverview':
                    return (
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('myClaimOverview', LocaleService.currentLanguage);
                            redirectToRoute(`claimOverview/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'benefitPayment':
                    return (
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('benefitPayment', LocaleService.currentLanguage);
                            redirectToRoute(`benefitPayment/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'healthCareBenefits':
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('healthCareBenefits', LocaleService.currentLanguage);
                            redirectToRoute(`healthCare/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'nonHealthCareBenefits':
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('nonHealthCareBenefits', LocaleService.currentLanguage);
                            redirectToRoute(`nonHealthCare/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'appeals':
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('appeals', LocaleService.currentLanguage);
                            redirectToRoute(`appeals/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'claimDocuments':
                    return (
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('uploadDocument', LocaleService.currentLanguage);
                            redirectToRoute(`documentUpload/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'documentHistory':
                    return (
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('submittedDocuments', LocaleService.currentLanguage);
                            redirectToRoute(`documentHistory/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'sendUsMessage':
                    return (
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('sendUsAMessage', LocaleService.currentLanguage);
                            redirectToRoute(`sendmessage/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'mail':
                    return (
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('mail', LocaleService.currentLanguage);
                            redirectToRoute(`mail/${claimNumber}`);
                        }}
                        >
                            {`${translator(message[key])}${count}`}
                        </Link>
                    );
                case 'faq':
                    return (
                        <Link onClick={() => {
                            redirectToRoute(`faq-link/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                case 'helpfulLink':
                    return (
                        <Link onClick={() => {
                            AnalyticsLinkService.sendLinkAnalyticsReport('leftPanelHelpfullink', LocaleService.currentLanguage);
                            redirectToRoute(`helpful-link/${claimNumber}`);
                        }}
                        >
                            {translator(message[key])}
                        </Link>
                    );
                default: return (
                    <Link onClick={() => { redirectToRoute(`claimOverview/${claimNumber}`); }}>
                        {translator(message[key])}
                    </Link>
                );
            }
        },
        [count, redirectToRoute, translator]
    );

    useEffect(() => {
        validateMetadata(uiMetadata);
        fetchUserName();
        displaySidenav();

        // jutro upgrade check
        // SE-1281 3.1.2 Language of Parts - Language is not set for the French word: "Français" 
        // dynamically set lang attribute when rendering headern component
        const langTextEl = document.querySelector('#navlinkTranslations > span');
        if (langTextEl) {
            langTextEl.setAttribute('lang', languageTag());
        }

        const iAmTab = document.getElementsByClassName('mir mi-expand-more jut__GenericSelectControl__selectIcon');
        Array.from(iAmTab).forEach(element => {
            element.removeAttribute('focusable');
        })

        const langTextPhoneEl = document.querySelector('#mobileNavlinkTranslations > span');
        if (langTextPhoneEl) {
            langTextPhoneEl.setAttribute('lang', languageTag());
        }
    });

    useEffect(() => {
        const logoLinkElement = document.getElementById('responsivelogolink');
        const currentLanguage = LocaleService.getStoredLanguage();
        if (currentLanguage === 'fr') {
            logoLinkElement?.setAttribute('title', 'Accueil. S’ouvre dans un nouvel onglet.');
        } else {
            logoLinkElement?.setAttribute('title', 'Home. Opens in a new tab.');
        }
    }, []);

    /*
     *  component level overrids
     */
    const override = {
        iAmDropdown: {
            visible: isUserLoggedIn,
            value: iAmType,
        },
        iAmDropdownLabel: {
            visible: isUserLoggedIn,
            content: fetchContent('iAm')
        },
        mobileiAmDropdownLabel: {
            visible: isUserLoggedIn,
            content: fetchContent('iAm')
        },
        responsiveiAmDropdown: {
            value: iAmType
        },
        username: {
            visible: isUserLoggedIn,
            content: `${fetchContent('username')} ${userName}`
        },
        mobileusername: {
            visible: isUserLoggedIn,
            content: `${fetchContent('username')} ${userName}`
        },
        modal: {
            visible: showModal
        },
        backdrop: {
            visible: showModal
        },
        navlinkMyClaims: {
            content: fetchContent('myclaims'),
            visible: isUserLoggedIn
        },
        navlinkProfile: {
            content: fetchContent('myProfile'),
            visible: isUserLoggedIn
        },
        navlinkLogout: {
            content: fetchContent('logout'),
            visible: isUserLoggedIn
        },
        mobilenavlinkMyClaims: {
            content: fetchContent('myclaims'),
            visible: isUserLoggedIn
        },
        mobilenavlinkProfile: {
            content: fetchContent('myProfile'),
            visible: isUserLoggedIn
        },
        mobilenavlinkLogout: {
            content: fetchContent('logout'),
            visible: isUserLoggedIn
        },
        navlinkTranslations: {
            content: fetchContent('french'),
            lang: languageTag(),
        },
        mobileNavlinkTranslations: {
            content: fetchContent('french')
        },
        menu: {
            content: fetchContent('menu')
        },
        mobiledocumentsLabel: {
            content: fetchContent('documents'),
            visible: OSBCClaimService.claimFeatures.claimUploadDocumentFeatureEnabled
            || OSBCClaimService.claimFeatures.claimUploadDocumentFeatureEnabled
        },
        mobilemessagesLabel: {
            content: fetchContent('messages'),
            visible: OSBCClaimService.claimFeatures.claimSecureMessagingFeatureEnabled
        },
        mobileresourcesLabel: {
            content: fetchContent('resources')
        },

        mobileclaimInformationLabel: {
            content: fetchContent('claimInformation')
        },
        mobileclaimOverviewLink: {
            content: navigateLink('claimOverview')
        },
        mobilebenefitPaymentLink: {
            content: navigateLink('benefitPayment')
        },
        mobilehealthCareBenefitslink: {
            content: navigateLink('healthCareBenefits')
        },
        mobilenonHealthCareBenefitslink: {
            content: navigateLink('nonHealthCareBenefits')
        },
        mobileappealslink: {
            content: navigateLink('appeals')
        },
        mobileclaimDocumentsLink: {
            content: navigateLink('claimDocuments')
        },
        mobileDocumentHistoryLink: {
            content: navigateLink('documentHistory')
        },
        mobilesendUsMessageLink: {
            content: navigateLink('sendUsMessage')
        },
        mobilemailLink: {
            content: navigateLink('mail')
        },
        mobileFaqLink: {
            content: navigateLink('faq')
        },
        mobileHelpfulLink: {
            content: navigateLink('helpfulLink')
        },
        onlineServiceLabel: {
            content: fetchContent('onlineServices')
        },
        navlinkExit: {
            content: getExitLink(),
            visible: !isUserLoggedIn
        },
        mobilenavlinkExit: {
            content: getExitLink(),
            visible: !isUserLoggedIn
        },
        iMADropsoenContentContainer: {
            visible: isUserLoggedIn
        },
        responsiveSidenav: {
            // content: metadata.leftNav.content,
            visible: !showLeftNav && isUserLoggedIn
        },
        mobilebenefitPaymentContainer: {
            visible: OSBCClaimService.claimFeatures.claimBenefitPaymentsFeatureEnabled
        },
        mobilehealthCareBenefitsContainer: {
            visible: OSBCClaimService.claimFeatures.claimHealthCareBenefitsFeatureEnabled
        },
        mobilenonHealthCareBenefitsContainer: {
            visible: OSBCClaimService.claimFeatures.claimNonHealthCareBenefitsFeatureEnabled
        },
        mobileappealsContainer: {
            visible: OSBCClaimService.claimFeatures.claimAppealsFeatureEnabled
        },
        mobileclaimDocumentsContainer: {
            visible: OSBCClaimService.claimFeatures.claimUploadDocumentFeatureEnabled
        },
        mobileDocumentHistoryContainer: {
            visible: OSBCClaimService.claimFeatures.claimDocumentHistoryFeatureEnabled
        },
        mobilesendUsMessageContainer: {
            visible: OSBCClaimService.claimFeatures.claimSecureMessagingFeatureEnabled
        },
        mobilemailContainer: {
            visible: OSBCClaimService.claimFeatures.claimSecureMessagingFeatureEnabled
        },
        unsupportedBrowserBannerCotainer: {
            visible: false
        },
        logolink: {
            title: translator(message.wsibLogoTitle)
        }
    };

    /*
     *  component level resolvers
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            logout,
            onChangeOfIamDropdown,
            menuIconClicked,
            closeModal,
            changeLanguage,
            navigateToMyProfile,
            navigateToMyClaims,
            onLogoClick
        }
    };

    return renderContentFromMetadata(
        uiMetadata.osbcHeaderComponent,
        override,
        resolvers
    );
}
