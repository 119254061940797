import React from 'react';
// import custom component header.
import Header from '../header/header';
// import custom component footer.
import Footer from '../footer/footer';
// import custom component ClaimSummaries.
import SessionExpired from '../session-expired/session-expired';

function SessionExpiredHome() {
    return (
        <div>
            <Header />
            <div tabIndex="-1" id="sessionExpiredBodySection" className="osbcMainContainer">
                <SessionExpired />
            </div>
            <Footer />
        </div>
    );
}

export default SessionExpiredHome;
